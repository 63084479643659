import { mapServices } from '../data/map-services';

/**
 * getMapServicesByName
 * @description Returns all preconfigured services
 */

export function getMapServices() {
  return mapServices || [];
}

/**
 * getMapServiceByName
 * @description Tries to find a service given the name. Allows additional services passed in.
 */

export function getMapServiceByName(name, userServices = []) {
  const services = [...getMapServices(), ...userServices];
  return services.find((service) => service.name === name);
}

export function coordinateToPixel([x, y]) { return [x, 270 - y] }
export function pixelToCoordinate([x, y]) { return [x, 270 - y]; }

export function islandToBounds(island) {
  return [
    [(island.y - Math.ceil(island.h / 2)) / 10, (island.x - Math.ceil(island.w / 2) + 1) / 10],
    [(island.y + Math.floor(island.h / 2)) / 10, (island.x + Math.floor(island.w / 2) + 1) / 10]
  ]
}

export function boundsToCoordinates(b: number[][]) {
  return [[b[0][1] / 10, b[0][0] / 10], [b[1][1] / 10, b[1][0] / 10]];
}

export function islandToPoint(island) {
  return [island.y / 10, island.x / 10];
}

export function latLongToXY([lat, long]) {
  return [Math.round(long * 10), Math.round(lat * 10)];
}

export const mapDeciStart = pixelToCoordinate([0, 0]);
export const mapDeciEnd = pixelToCoordinate([441, 306]);

export const mapBounds = [[-36 / 10, 0 / 10], [270 / 10, 441 / 10]];
export const mapCenter = [(mapBounds[0][0] + mapBounds[0][1]) / 2, (mapBounds[1][0] + mapBounds[1][1]) / 2];
